import { styled, SxProps, Typography } from "@mui/material";
import { ReactElement } from "react";
import colors from "../../../colors";

const CardContainer = styled(
  "div",
  {
    label: "CardContainer",
    shouldForwardProp: (prop => prop !== "sx")
  })({
  border: `1px solid ${colors.black200}`,
  borderRadius: 8,
  padding: "24px",
});

const CardHeader = styled(Typography, {label: "CardContainer"})({
  fontWeight: 600,
  color: colors.black900,
  fontSize: 16,
  lineHeight: '24px'
});

const CardBody = styled("div", {label: "CardContainer"})({
  marginTop: 5,
  color: colors.black600,
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 600
});

export type CabCardProps = {
  headerContent: string
  children: string | ReactElement | ReactElement[]
  sx?: SxProps
};

export const CabCard = ({headerContent, children, sx}: CabCardProps) => {
  return <CardContainer sx={sx}>
    <CardHeader>{headerContent}</CardHeader>
    <CardBody>{children}</CardBody>
  </CardContainer>;
};


export default CabCard;